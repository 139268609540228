import SpinnerGhost from './assets/Ghost.gif';

function Spinner() {
  return (
    <div className='w-100 mt-20'>
      <img
        width={100}
        src={SpinnerGhost}
        className='text-center mx-auto'
        alt='Loading..'
      />
    </div>
  );
}

export default Spinner;
